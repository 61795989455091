import InternalBanner from "../components/InternalBanner";
import CreateProposal from "../components/CreateProposal";
import ListProposals from "../components/ListProposals";
import { useState } from 'react';

const PageCreate = () => {

	const [uprops,setUprops] = useState(null);
	
	return ( 
	
	<>
		
	<InternalBanner background="bg-pg-yellow" title="Create" titleColor="text-pg-black" />
	
	<CreateProposal uprops={uprops} setUprops={setUprops} />
	
	<ListProposals uprops={uprops} setUprops={setUprops} />
	
	</>
	
	);
}
 
export default PageCreate;