import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, createContext } from 'react';
import { PageLogin, PageDiscover, PageHome, PageLearn } from './pages/Pages';
import { currentSession } from "./auth/supabase";
import { AuthContext } from './components/AuthContext';
import PageDashboard from "./pages/PageDashboard";
import PageCreate from "./pages/PageCreate";
import PageSingleProposal from "./pages/PageSingleProposal";

function App() {

	const [sCurrent,setSCurrent] = useState(null);

	const sesh = currentSession();
	
	sesh.then((result)=> {
		
		if(sCurrent === null){
		
			setSCurrent(result.session.user);
		
		}
	
	});
			
	console.log(sCurrent);
	
  return (
    <AuthContext.Provider value={{sCurrent,setSCurrent}}>
    <div className="App font-jakarta font-medium bg-pg-gray">
    
      <BrowserRouter>
	    
				<Header />
      
        <Routes>
					
					<Route index element={<PageHome />} />
					
					<Route path="discover" element={<PageDiscover />} />
					
					<Route path="dashboard" element={<PageDashboard />} />
					
					<Route path="create" element={<PageCreate />} />
					
					<Route path="proposal" element={<PageDiscover />} />
					
					<Route path="proposal/:id" element={<PageSingleProposal />} />
					
					<Route path="learn" element={<PageLearn />} />
					
					<Route path="login" element={<PageLogin />} />
        
        </Routes>
      
      </BrowserRouter>
	    
    </div>
    </AuthContext.Provider>
  );
}

export default App;
