import InternalBanner from "../components/InternalBanner";
import { logoutGoogle } from '../auth/supabase';
import { AuthContext } from '../components/AuthContext';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";

const PageDashboard = () => {

	const { sCurrent,setSCurrent } = useContext(AuthContext);
	
	const Navigate = useNavigate();
	
	return ( 
	
	<>
		
	<InternalBanner background="bg-pg-red" title="Dashboard" />
	
	<div className="container mx-auto py-16">
	
		<button className="py-3 px-4 text-md mt-4 w-full bg-pg-black text-pg-white rounded-md cursor-pointer max-w-max active:scale-active" onClick={ () => {logoutGoogle(); setSCurrent(false);Navigate('/')} }>Log out with Google</button>
	
	</div>
	
	
	
	</>
	
	);
}
 
export default PageDashboard;