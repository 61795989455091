import { createClient } from '@supabase/supabase-js';

const InternalBanner = ({title = "This is a title", background = "bg-pg-black", titleColor = "text-pg-white"}) => {

	return ( 
	
		<section>
		
			<div className={`${background} min-h-[25vh] py-10 flex flex-col justify-center`}>
		
				<div className="container mx-auto">
				
					<h1 className={`text-5xl ${titleColor} max-w-4xl`}>{title}</h1>
				
				</div>
				
			</div>
		
		</section>
	
	);
}
 
export default InternalBanner;