import { Link } from "react-router-dom";
import InlineButton from "./InlineButton";
import { useContext } from 'react';
import { AuthContext } from './AuthContext';

const Header = () => {

	const {sCurrent} = useContext(AuthContext);

	return ( 
	
		<header className="py-8 border-b border-pg-white bg-pg-black">
		
			<div className="container mx-auto flex items-center justify-between">
			
				<Link to="/"><h1 className="text-xl text-white group">kikstart<span className="group-hover:text-pg-red">.io</span></h1></Link>
				
				<ul className="flex align-center gap-8">
				
					<li><InlineButton title="Discover" hover="hover:text-pg-blue" link="/discover" /></li>
				
					<li><InlineButton title="Learn" hover="hover:text-pg-green" link="/learn" /></li>
					
					{ sCurrent && <li><InlineButton title="Create" hover="hover:text-pg-yellow" link="/create" /></li> }
					
					{ !sCurrent &&  <li><InlineButton title="Log In" hover="hover:text-pg-yellow" link="/login" /></li>}
					
					{ sCurrent && <li> <Link to="/dashboard"><img className="block h-7 rounded-full cursor-pointer border-2 border-transparent hover:border-pg-red" src={sCurrent.user_metadata.picture} alt=""  referrerPolicy="no-referrer"/> </Link></li> }
				
				</ul>
			
			</div>
		
		</header>
	
	);
}
 
export default Header;