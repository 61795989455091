import { AuthContext } from './AuthContext';
import { useContext } from 'react';
import { supabase } from '../auth/supabase';

const CreateProposal = ({uprops,setUprops}) => {

	const {sCurrent, setSCurrent} = useContext(AuthContext);

	const addProp = async (e) => {
	
		const prop_name = document.getElementById('create-name').value;
		const prop_id = document.getElementById('create-id').value;
	
		e.preventDefault();
		
		
		const { data, error } = await supabase
		.from('Proposals')
		.insert([
			{ 
			Name: prop_name,
			'User Id': prop_id
			},
		]);
		
		setUprops(prop_name);
	
	};

	return ( 
	
	<section>
	
		<div className="my-16">
		
			<div className="container mx-auto">
			
				<form id="create-form" className='flex flex-col max-w-sm mx-auto gap-4 p-8 bg-pg-white border-3 border-pg-black rounded-lg drop-shadow-pg-4' onSubmit={addProp}>
				
					<input id="create-name" required type="text" placeholder="Proposal Name" className='text-md p-4 rounded-md border-2 border-pg-black focus:outline-none' />
					
					{sCurrent && <input id="create-id" readOnly type="hidden" value={sCurrent.id} className='text-md p-4 rounded-md border-2 border-pg-black focus:outline-none' />}
					
					<input type="submit" value="Create Proposal" className="py-3 px-4 text-md mt-4 w-full bg-pg-black text-pg-white rounded-md cursor-pointer active:scale-active" />
				
				</form>
			
			</div>
		
		</div>
	
	</section>
	
	);
}
 
export default CreateProposal;