import { Link } from "react-router-dom";

const InlineButton = ({title = "learn more", hover = "hover:text-pg-red", link = "/"}) => {
	return ( 
	
	<Link to={link}  className={`text-pg-white ${hover} hover:underline underline-offset-8`}>{title}</Link>
	
	);
}
 
export default InlineButton;