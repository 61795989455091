import { Link } from "react-router-dom";

const ProductCard = () => {
	return ( 
	
		<li className="overflow-hidden border-3 rounded-lg border-pg-black drop-shadow-pg-4"><Link to="/">
		
			<img className="aspect-video object-cover block" src={require("../images/product-test.png")} alt="" />
			
			<div className="bg-pg-white p-4">
			
				<div className="flex align-center justify-between mb-4">
			
					<h3>Product name</h3>
				
					<p>£39.99</p>
				
				</div>
				
				<p className="opacity-50">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sed hic repellendus voluptates mollitia.</p>
			
			</div>
		
		</Link></li>
	
	);
}
 
export default ProductCard;