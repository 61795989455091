import Button from "./Button";
import { AuthContext } from "./AuthContext";
import { useContext } from "react";

const Hero = () => {

	const {sCurrent,setSCurrent} = useContext(AuthContext);

	return ( 
	
		<section>
		
			<div className="hero min-h-[calc(100vh-93px)] bg-pg-black flex flex-col justify-center pb-[93px]">
			
				<div className="container mx-auto flex flex-col gap-16">
				
					<h1 className="text-8xl text-pg-white max-w-4xl">Get proposals to clients quicker</h1>
					
					{ sCurrent ? 
					
						<p className="text-pg-white text-xl max-w-4xl"> Hey {sCurrent.user_metadata.name}, Stop spending time on proposals and get to the good stuff 🤟</p>
						
						:
						
						<p className="text-pg-white text-xl max-w-4xl">Stop spending time on proposals and get to the good stuff 🤟</p>
					
					}
			
					{sCurrent ? <Button theme="dark" title="Create a proposal" link="/create" /> : <Button theme="dark" title="Get Started" link="/login" />}
				
				</div>
			
			</div>
		
		</section>
	
	);
}
 
export default Hero;