import { createClient } from '@supabase/supabase-js';

export const supabase = createClient(
  'https://iimkwlnscswspmjplwey.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlpbWt3bG5zY3N3c3BtanBsd2V5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjIyMjQ2NTIsImV4cCI6MTk3NzgwMDY1Mn0.kkHxAdG-HdJHrpCnmCAs_m8_cRqOUcQGo5gxZeAgbdY'
);

export async function loginGoogle() {
	const { user, session, error } = await supabase.auth.signInWithOAuth({
		provider: 'google'
	});
}

export async function logoutGoogle() {

	const { error } = await supabase.auth.signOut();

}

export async function currentSession() {

	const { data, error } = await supabase.auth.getSession();
	
	// console.log(data,error);
	
	return(data);

}