import LoginForm from "../components/LoginForm";
import InternalBanner from "../components/InternalBanner";
import ProductGrid from "../components/ProductGrid";
import Hero from "../components/Hero";

export const PageHome = () => {
	return ( 
	
		<>
		
			<Hero />
		
		</>
	
	);
};

export const PageLogin = () => {

	return(
	
		<LoginForm />
	
	);

};

export const PageDiscover = () => {

	return(
	
		<>
			
			<InternalBanner background="bg-pg-blue" title="Discover" />
			
			<ProductGrid />
		
		</>
	
	);

};

export const PageLearn = () => {
	return ( 
	
	<>
		
		<InternalBanner background="bg-pg-green" title="Learn" />
	
	</>
	
	);
};