import { useParams } from "react-router-dom";
import InternalBanner from "../components/InternalBanner";
import { supabase } from '../auth/supabase';
import { useEffect, useState } from 'react';
 
const PageSingleProposal = () => {

	const params = useParams();
	
	const [cProp,setCProp] = useState(null);
	
	const currentProposal = async () => {
	
		
		let { data: Proposals, error } = await supabase
	  .from('Proposals')
	  .select("*")
	  .eq("UUID", params.id);
		
		setCProp(Proposals[0]);
	
	}; 
	
	useEffect( () => {
	
		currentProposal();
	
	},[] );

	return ( 
	
	<>
	
	{ cProp && <InternalBanner background="bg-pg-blue" title={cProp.Name} />}
	
	</>
	
	);
}
 
export default PageSingleProposal;