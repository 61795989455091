import { useState, useContext } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { loginGoogle, logoutGoogle } from '../auth/supabase';
import { AuthContext } from './AuthContext';

const LoginForm = () => {

	const { sCurrent,setSCurrent } = useContext(AuthContext);
	
	return ( 
	
		<section>
		
			<div className="min-h-[calc(100vh-93px)] pb-[93px] bg-pg-yellow flex flex-col justify-center">
			
				<div className="container mx-auto">
				
					<div className="bg-pg-white mx-auto max-w-sm border-3 border-pg-black rounded-lg drop-shadow-pg-4 overflow-hidden p-8">
							
							<h3 className="text-3xl mb-8">Log In</h3>
							
							{ !sCurrent ? 
							
							<button className="py-3 px-4 text-md mt-4 w-full bg-pg-red text-pg-white rounded-md cursor-pointer active:scale-active" onClick={ () => loginGoogle() }>Log in with Google</button>
							
							:
							
							<button className="py-3 px-4 text-md mt-4 w-full bg-pg-black text-pg-white rounded-md cursor-pointer active:scale-active" onClick={ () => {logoutGoogle(); setSCurrent(false);} }>Log out with Google</button>
							
							}
						
					</div>
				
				</div>
			
			</div>
		
		</section>
	
	);
}
 
export default LoginForm;