import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const Button = ({title, theme, link = "/"}) => {

	if(theme === "dark"){

		return ( 
			
			<Link className="bg-pg-black text-pg-white leading-none py-5 px-5 self-start active:scale-active border border-pg-white flex aling-center gap-2" to={link} > {title} <BsArrowRight/> </Link>
		
		);
	
	}
}
 
export default Button;