import ProductCard from "./ProductCard";

const ProductGrid = () => {

	var products = [1,2,3,4,5,6];

	return ( 
	
	<section>
	
		<div className="py-24">
		
			<div className="container mx-auto">
			
				<ul className="grid grid-cols-3 gap-8">
				
					{products.map( (card) => {
					
						return(<ProductCard key={`key_${card}`} />)
					
					} )}
				
				</ul>
			
			</div>
		
		</div>
	
	</section>
	
	);
}
 
export default ProductGrid;