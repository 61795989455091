import { useEffect, useState, useContext } from 'react';
import { supabase } from '../auth/supabase';
import { FaRegTrashAlt } from "react-icons/fa";
import { FiLink } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { AuthContext } from './AuthContext';


const ListProposals = ({uprops,setUprops}) => {

	const [proposals,setProposals] = useState(null);

	const { sCurrent,setSCurrent } = useContext(AuthContext);
	
	useEffect( () => {
	
		const getProposals = async () => {
		
		
			let { data: Proposals, error } = await supabase
			.from('Proposals')
			.select("*")
			.eq('User Id', sCurrent.id);
			
			setProposals(Proposals);
		
		}; getProposals();
	
	},[uprops] );
	
	const del_prop = async (id) => {
	
		const { data, error } = await supabase
			.from('Proposals')
			.delete()
			.eq('id', id)
			
		setUprops(`deleted_${id}`);
	
	};

	return ( 
	
		<section>
		
			<div className='mb-16'>
			
				<div className="container mx-auto">
				
					<ul className='flex flex-col flex-col-reverse gap-4'>
					
						{proposals && proposals.map( (prop) => {
						
							return(<li className='py-4 px-8 bg-pg-white border-2 border-pg-black rounded-lg drop-shadow-pg-2 flex align-center justify-between' key={prop.id}>
								
								<p className='text-lg'>{prop.Name}</p>
								
								<ul className='flex gap-3 align-center justify-center'>
									
									<li className='text-lg flex flex-col justify-center cursor-pointer'> <Link to={`/proposal/${prop.UUID}`}><FiLink /></Link> </li>
								
								<li className='text-lg flex flex-col justify-center cursor-pointer' onClick={ () => del_prop(prop.id)}><FaRegTrashAlt /></li>
								
								</ul>
								
							</li>)
						
						} )}
					
					</ul>
				
				</div>
			
			</div>
		
		</section>
	
	);
}
 
export default ListProposals;